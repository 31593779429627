import { object, string, date, number, setLocale } from 'yup';

setLocale({
  mixed: {
    default: 'Helytelen adat',
    required: 'Kötelező megadni',
    notType: 'Helytelen formátum'
  },
  string: {
    matches: 'Helytelen formátum',
    min: 'Minimum ${min} karakter',
    max: 'Maximum ${max} karakter'
  },
  number: {
    positive: 'Pozitív számnak kell lennie',
    integer: 'Egész számnak kell lennie'
  }
});

export const QRSchema = object().shape({
  name: string().trim().max(70).required(),
  iban: string().trim().length(28, 'IBAN 28 karakter').matches(/^([A-Z]{2}[0-9]{2})\d{24}$/).required(),
  bic: string().trim().min(8,).max(11).matches(/^[A-z]{4}[A-z]{2}\w{2}(\w{3})?$/).required(),
  amount: number().positive().integer(),
  expire: date().min(new Date()).required(),
  message: string().trim().max(70),
  purpose: string().trim().length(4).matches(/^[A-Z]{4}$/),
  shopID: string().trim().max(35),
  merchDevID: string().trim().max(35),
  invoiceID: string().trim().max(35),
  customerID: string().trim().max(35),
  credTranID: string().trim().max(35),
  loyaltyID: string().trim().max(35),
  NAVCheckID: string().trim().max(35)
});
