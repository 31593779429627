
export default function AppIcon({ title, href, imgSrc, ...props }) {
  return (
    <div className="app-icon-container">
      <a href={href} target="_blank" rel="noreferrer" className="text-white">
        <div className="app-icon">
          <img src={imgSrc} alt={title} {...props} />
        </div>
        <h6>{title}</h6>
      </a>
    </div>
  );
}
