import { Fragment } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import { getDateString } from '../utils';
import PurposeSelect from './PurposeSelect';
import InputField from './InputField';
import AmountField from './AmountField';
import ToggleSimpleButtons from './ToggleSimpleButtons';
import { QRSchema } from '../utils/validation_schema';

const expireDays = 7;
const initialExpire = new Date();
initialExpire.setDate(initialExpire.getDate() + expireDays);

const simpleInitialValues = {
  name: '',
  iban: '',
  bic: '',
  amount: '',
  expire: getDateString(initialExpire),
  message: ''
};

const advancedInitialValues = {
  purpose: '',
  shopID: '',
  merchDevID: '',
  invoiceID: '',
  customerID: '',
  credTranID: '',
  loyaltyID: '',
  NAVCheckID: ''
};

export default function QRDataForm({ onSubmit, onReset, disabled }) {
  const formik = useFormik({
    initialValues: Object.assign({}, simpleInitialValues, advancedInitialValues),
    validationSchema: QRSchema,
    onSubmit
  });
  const [simple, setSimple] = useState(true);

  function handleSimpleChange(isSimple) {
    if (simple !== isSimple) {
      setSimple(isSimple);

      if (isSimple) {
        formik.setValues(Object.assign({}, formik.values, advancedInitialValues));
      }
    }
  }

  return (
    <form  className="row" onSubmit={formik.handleSubmit}>
      <fieldset className="row g-2" disabled={disabled}>

        <div className="col-12 text-center">
          <ToggleSimpleButtons isSimple={simple} onChange={handleSimpleChange} />
        </div>

        <InputField
          name="name"
          label="Kedvezményezett neve"
          placeholder="Minta Kft."
          maxLength="70"
          formik={formik}
          required
          autoFocus
        />

        <InputField
          className="col-md-8 col-sm-12"
          name="iban"
          label="IBAN"
          placeholder="HUxx xxxx xxxx xxxx xxxx xxxx xxxx"
          minLength="28"
          maxLength="28"
          formik={formik}
          required
        />

        <InputField
          className="col-md-4 col-sm-12"
          name="bic"
          label="BIC"
          placeholder="BICHUTEST"
          minLength="8"
          maxLength="11"
          formik={formik}
          required
        />

        <InputField className="col-md-5 col-sm-12" name="amount" label="Összeg" formik={formik} showFeedback={false}>
          <AmountField formik={formik} />
        </InputField>

        <InputField
          className="col-md-7 col-sm-12"
          name="expire"
          label="Érvényességi idő"
          type="datetime-local"
          min={getDateString(new Date())}
          formik={formik}
          required
        />

        <InputField
          name="message"
          label="Közlemény"
          maxLength="70"
          formik={formik}
        />

        {!simple &&
          <Fragment>
            <InputField className="col-md-6 col-sm-12" name="purpose" label="Fizetési helyzet azonosító" formik={formik}>
              <PurposeSelect
                name="purpose"
                isDisabled={disabled}
                value={formik.values.purpose}
                onBlur={formik.handleBlur}
                onChange={option => formik.setFieldValue('purpose', option ? option.value : '')}
              />
            </InputField>

            <InputField
              className="col-md-6 col-sm-12"
              name="shopID"
              label="Kereskedelmi egység, bolt azonosító"
              maxLength="35"
              formik={formik}
            />

            <InputField
              className="col-md-6 col-sm-12"
              name="merchDevID"
              label="POS, pénztárgép azonosító"
              maxLength="35"
              formik={formik}
            />

            <InputField
              className="col-md-6 col-sm-12"
              name="invoiceID"
              label="Számla vagy nyugta azonosító"
              maxLength="35"
              formik={formik}
            />

            <InputField
              className="col-md-6 col-sm-12"
              name="customerID"
              label="Ügyfélazonosító"
              maxLength="35"
              formik={formik}
            />

            <InputField
              className="col-md-6 col-sm-12"
              name="credTranID"
              label="Belső tranzakcióazonosító"
              maxLength="35"
              formik={formik}
            />

            <InputField
              className="col-md-6 col-sm-12"
              name="loyaltyID"
              label="Törzsvásárlói azonosító"
              maxLength="35"
              formik={formik}
            />

            <InputField
              className="col-md-6 col-sm-12"
              name="NAVCheckID"
              label="NAV ellenőrző kód"
              maxLength="35"
              formik={formik}
            />
          </Fragment>
        }
      </fieldset>

      <InputField className="col-12 text-center mt-2" formik={formik} showFeedback={false}>
        {disabled ? (
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={e => {
              e.preventDefault();
              onReset();
            }}
          > 
            Adatok szerkesztése
          </button>
        ) : (
          <button
            type="submit"
            className="btn btn-secondary"
            disabled={formik.isValidating || formik.isSubmitting}
          >
            QR-kód generálás
          </button>
        )}
      </InputField>
      
    </form>
  );
}
