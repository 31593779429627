import classNames from 'classnames';

export default function Footer({ isDark = false }) {
  return (
    <footer className={classNames({ 'bg-dark text-white': isDark })}>
      <div className="container">
        <div className="row text-center">
          <p>Copyright © {(new Date()).getFullYear()} QR transfer</p>
        </div>
      </div>
    </footer>
  );
}