import { Fragment } from "react";
import Footer from './Footer';
import NavigationBar from './NavigationBar';
import QRContainer from './QRContainer';
import AppIcon from './AppIcon';
import AppScreenshot from './AppScreenshot';
import PageContent from './PageContent';

export default function App() {
  return (
    <Fragment>
      <NavigationBar />
      <PageContent id="intro" isDark title="A QR-kód egy újabb felhasználási lehetősége">
        <div className="row">
          <div className="col text-center">
            <p>
            A széles körben alkalmazott QR-kód leegyszerűsíti a banki utalás folyamatát azáltal,
            hogy a kód tartalmaz minden információt, ami az utaláshoz szükséges.

              A kód generálás a fizetési folyamatok lebonyolításához az <a href="https://www.mnb.hu/penzforgalom/azonnalifizetes/utmutatok" target="_blank" rel="noreferrer">MNB útmutatása</a> alapján történik.
            </p>
          </div>
        </div>
      </PageContent>
      <PageContent id="generate" title="QR-kód generálása átutalási megbízáshoz">
        <QRContainer />
      </PageContent>
      <PageContent id="usage" isDark title="Hol tudom használni ?">
        <div className="row">
          <p>
            A QR-kódos utalásra jelenleg három banknál van lehetőség. 2021-ben pedig további banki mobilapplikációk fognak bővülni ezzel a szolgáltatással.
          </p>
          <div className="col text-center">
            <AppIcon
              title="GRÁNIT eBank"
              href="https://granitbank.hu/ebank/"
              imgSrc="/images/granit_ebank.webp"
              className="w-100 h-100"
            />
            <AppScreenshot imgSrc="/images/granit_ebank_qr.webp" title="GRÁNIT eBank" />
          </div>
            
          <div className="col text-center">
            <AppIcon
              title="myRaiffeisen"
              href="https://www.raiffeisen.hu/myraiffeisen-app"
              imgSrc="/images/myraiffeisen.webp"
              className="w-100 h-100"
            />
            <AppScreenshot imgSrc="/images/myraiffeisen_qr.webp" title="myRaiffeisen" />
          </div>

          <div className="col text-center">
            <AppIcon
              title="Erste George"
              href="https://www.erstebank.hu/hu/ebh-nyito/mindennapi-penzugyek/elektronikus-szolgaltatasok/george-app"
              imgSrc="/images/erste_george.webp"
              className="w-100 h-100"
            />
            <AppScreenshot imgSrc="/images/erste_george_qr.webp" title="Erste Bank George App" />
          </div>
        </div>
      </PageContent>
      <Footer isDark />
    </Fragment>
  );
}
