import { useState, useEffect } from 'react';
import QRCode from 'qrcode';

export default function QRCodeSVG({ value, ...props }) {
  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function generateSVGData() {
      try {
        const svgStr = await QRCode.toString(value, { type: 'svg', errorCorrectionLevel: 'M', margin: 0 });
        const buffer = Buffer.from(svgStr);
        setData(`data:image/svg+xml;base64,${buffer.toString('base64')}`);
      } catch(error) {
        console.error(error);
        setErrorMessage(error.message);
      }
    }
    
    generateSVGData();
  }, [value]);
  
  if (errorMessage !== null) {
    return (
      <div className="alert alert-warning" role="alert">{errorMessage}</div>
    );
  }

  if (data !== null) {
    return (
      <img alt="QR-kód" src={data} {...props} />
    );
  }

  return null;
};
