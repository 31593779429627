import { useState } from 'react';
import DownloadPngButton from './DownloadPngButton';
import QRDataForm from './QRDataForm';
import { generateQRValue } from '../utils';
import QRCodeSVG from './QRCodeSVG';

export default function QRContainer() {
  const [value, setValue] = useState(null);
  const hasValue = value !== null;

  function setQRValue(transactionData, formikBag) {
    setValue(
      generateQRValue(transactionData)
    );

    formikBag.setSubmitting(false);
  }

  function resetQRValue() {
    setValue(null);
  }

  return (
    <div className="row">
      <div className="col-md-6 col-sm-12">
        <div className="row">
          <div className="col">
            <QRDataForm onSubmit={setQRValue} onReset={resetQRValue} disabled={hasValue} />
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-12">
        <div className="row text-center fw-bold">
          <div className="col">QR-kód</div>
        </div>
        <div className="row">
          <div className="col text-center m-3">
            {hasValue ? (
              <QRCodeSVG id="qr-kod" value={value} style={{ width: 230 }} />
            ) : (
              <div className="alert alert-info" role="alert">A QR-kód generáláshoz add meg a fizetés részleteit</div>
            )}
          </div>
        </div>
        {hasValue &&
          <div className="row">
            <div className="col text-center">
              <DownloadPngButton />
            </div>
          </div>
        }
      </div>
    </div>
  );
}