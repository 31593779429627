
export const PurposeCode = {
  CBLK: 'Csoportos kártyaklíring',
  CDCB: 'Készpénzfelvéttel kombinált kártyás fizetés',
  CDCD: 'Készpénzfelvét',
  CDCS: 'Különdíjas  készpénzfelvét',
  CDDP: 'Előautorizált kártyaművelet elszámolása',
  CDOC: 'Előzmény nélküli jóváírás',
  CDQC: 'Kvázi készpénz (utalvány)',
  ETUP: 'Elektronikus pénztárca feltöltése',
  FCOL: 'Kártyadíj beszedése',
  MTUP: 'Mobil feltöltés',
  ACCT: 'Átvezetés',
  CASH: 'CashManagementTranszfer',
  COLL: 'Követelés beszedése',
  CSDB: 'Készpénz kifizetés',
  DEPT: 'Betét',
  INTC: 'Vállalatcsoporton belüli fizetés',
  LIMA: 'Likviditáskezelés',
  NETT: 'Nettó elszámolás',
  AGRT: 'Mezőgazdasági transzfer',
  AREN: 'Vevő folyószámla tétel',
  BEXP: 'Üzleti kiadások',
  BOCE: 'konverziós könyvelés',
  COMC: 'Kereskedelmi fizetés',
  CPYR: 'Szerzői jogdíj',
  GDDS: 'Áru adás-vétele',
  GDSV: 'Áru és szolgáltatás adás-vétele',
  GSCB: 'Készpénzfelvéttel kombinált kártyás vásárlás',
  LICF: 'Licencdíj',
  POPE: 'Point of Purchase Entry',
  ROYA: 'Szabadalmi díj',
  SCVE: 'Szolgáltatások adás/vétele',
  SUBS: 'Előfizetés',
  SUPP: 'Szállítónak történő fizetés',
  TRAD: 'Kereskedelmi ügyleti szolgáltatás',
  CHAR: 'Jótékonysági adomány',
  COMT: 'Közvetítő harmadik fél gyűjtött befizetése',
  ECPG: 'Garantált e-fizetés',
  ECPR: 'e-fizetés visszautasítása',
  ECPU: 'Nem garantált e-fizetés',
  CLPR: 'Autóhitel tőketörlesztés',
  DBTC: 'Beszedés számlaterheléssel',
  GOVI: 'Állami biztosítás',
  HLRP: 'Lakáshitel törlesztése',
  INPC: 'Gépjármű biztosítás díja',
  INSU: 'Biztosítási díj',
  INTE: 'Kamat',
  LBRI: 'Alkalmazottak biztosítása',
  LIFI: 'Életbiztosítás',
  LOAN: 'Kölcsönfolyósítás',
  LOAR: 'Hiteltörlesztés',
  PENO: 'Bírósági határozaton alapuló fizetés (kivéve tartásdíj)',
  PPTI: 'Ingatlanbiztosítás',
  RINP: 'Rendszeres részletfizetés',
  TRFD: 'TrustFund (megkötésekkel kezelt vagyon)',
  ADMG: 'Adminisztrációs fizetés',
  ADVA: 'Előlegfizetés',
  BCDM: 'Belföldi csekktulajdonos',
  BCFG: 'Külföldi csekktulajdonos',
  BLDM: 'Épületfenntartás',
  CBFF: 'Hosszútávú megtakarítás',
  CBFR: 'Nyugdíj előtakarékosság',
  CCRD: 'Hitelkártyás fizetés',
  CDBL: 'Hitelkártya számla kiegyenlítése',
  CFEE: 'Törlési díj',
  CGDD: 'Kártyával generált beszedés',
  COST: 'Költségek',
  CPKC: 'Parkolási díj',
  DCRD: 'Debit kártyás fizetés',
  DSMT: 'Nyomtatott kifizetési kötelezvény',
  EDUC: 'Tandíj',
  FAND: 'Természeti katasztrófák utáni helyreállítást célzó állami kifizetés',
  FCPM: 'Díj és költségek késedelmes megfizetése',
  GOVT: 'Államkincstári fizetés',
  ICCP: 'Visszavonhatatlan hitelkártyás fizetés',
  IDCP: 'Visszavonhatatlan betétikártyás fizetés',
  IHRP: 'Bérlet/vásárlás részletfizetése',
  INSM: 'Részletfizetés',
  IVPT: 'Számlafizetés',
  MCDM: 'Belföldi többféle pénznemű csekk',
  MCFG: 'Külföldi többféle pénznemű csekk',
  MSVC: 'Többféle szolgáltatás',
  NOWS: 'Egyéb, meg nem határozott',
  OCDM: 'Belföldi csekk kötelezvény',
  OCFG: 'Külföldi csekk kötelezvény',
  OFEE: 'Nyitási díj',
  OTHR: 'Egyéb',
  PADD: 'Beszedés előzetes felhatalmazás alapján',
  PTSP: 'Fizetési feltételek',
  RCKE: 'Újra-bemutatott csekk',
  RCPT: 'Nyugta fizetés',
  REBT: 'Utólagos engedmény',
  REFU: 'Visszatérítés',
  RENT: 'Bérleti díj',
  RIMB: 'Korábbi hibás művelet visszafizetése',
  STDY: 'Tanulmányok költsége',
  TBIL: 'távközlési számla',
  TCSC: 'Önkormányzati díjak',
  TELI: 'Telefonon kezdeményezett fizetés',
  TRVC: 'Utazási csekk',
  WEBI: 'Interneten kezdeményezett művelet',
  ANNI: 'Évjáradék',
  CAFI: 'Letétkezelői díj (belső köri tétel)',
  CFDI: 'Esedékes tőke (belső köri)',
  CMDT: 'Árupiaci fizetés',
  DERI: 'Származékos ügylet',
  DIVD: 'Osztalék',
  FREX: 'Devizaügylet',
  HEDG: 'Fedezeti ügylet',
  INVS: 'Befektetés és értékpapírok',
  PRME: 'Nemesfém',
  SAVG: 'Megtakarítás',
  SECU: 'Értékpapír ügylet',
  SEPI: 'Értékpapír vásárlás (belső köri)',
  TREA: 'Treasury fizetés',
  ANTS: 'Aneszteziológiai szolgáltatás',
  CVCF: 'Gyógykezelés',
  DMEQ: 'Tartós orvosi felszerelés',
  DNTS: 'Fogászati szolgáltatások',
  HLTC: 'Otthoni ápolás',
  HLTI: 'Egészségbiztosítás',
  HSPC: 'Kórházi ápolás',
  ICRF: 'Idős- és beteggondozás',
  LTCF: 'Tartós gondozás',
  MDCS: 'Egészségügyi szolgáltatás',
  VIEW: 'Szemészeti szolgáltatás',
  ALLW: 'Ellátmány',
  ALMY: 'Tartásdíj',
  BBSC: 'Gyermekszületési támogatás',
  BECH: 'Családi pótlék',
  BENE: 'Munkanélküli-, rokkantellátás',
  BONU: 'Bónusz kifizetés',
  COMM: 'Megbízási díj',
  CSLP: 'Vállalati kölcsön folyósítás lebonyolító banknak',
  GVEA: 'A kategóriás osztrák állami alkalmazott',
  GVEB: 'B kategóriás osztrák állami alkalmazott',
  GVEC: 'C kategóriás osztrák állami alkalmazott',
  GVED: 'D kategóriás osztrák állami alkalmazott',
  PAYR: 'Bérlista',
  PENS: 'nyugdíjfolyósítás',
  PRCP: 'Árhoz kötött kifizetés',
  SALA: 'Fizetés folyósítása',
  SSBE: 'Társadalombiztosítási juttatás',
  AEMP: 'Aktív foglalkoztatás támogatása',
  GFRP: 'Garanciaalapból történő bérfizetés (fizetésképtelenség esetén)',
  GWLT: 'Hadirokkantak és háborús áldozatok járadéka',
  HREC: 'Választható juttatásként nyújtott munkáltatói lakáscélú támogatás',
  RHBS: 'Rehabilitációs támogatás',
  ESTX: 'Ingatlanadó',
  FWLV: 'Külföldi alkalmazott miatti járulék',
  GSTX: 'Áru- és szolgáltatás adója',
  HSTX: 'Építményadó',
  INTX: 'Jövedelemadó',
  NITX: 'Nettójödelem adó',
  PTXP: 'Ingatlanadó',
  RDTX: 'Útadó',
  TAXS: 'Adófizetés',
  VATX: 'ÁFA fizetés',
  WHLD: 'Forrásadó',
  TAXR: 'Adóvisszatérítés',
  AIRB: 'Légiközlekedés',
  BUSB: 'Buszközlekedés',
  FERB: 'Kompközlekedés',
  RLWY: 'Vasút',
  TRPT: 'Közút',
  CBTV: 'Kábel TV számla',
  ELEC: 'Villanyszámla',
  ENRG: 'Energiaszámla',
  GASB: 'Gázszámla',
  NWCH: 'Hálózathasználati díj',
  NWCM: 'Hálózati kommunikáció',
  OTLC: 'Egyéb, távközléshez kapcsolódó számla',
  PHON: 'Telefonszámla',
  UBIL: 'Közműszolgáltató számla',
  WTER: 'Vízszámla',
};
