import React from 'react';
import classNames from 'classnames';

export default function InputField({
  label, name, children, formik, type = 'text', required = false,
  className = 'col-12', showFeedback = true, ...props
}) {
  const id = props.id || name;
  const { values, touched, errors, handleBlur, handleChange } = formik;

  return (
    <div className={className}>
      {label !== undefined && <label htmlFor={id} className="form-label">{label}</label>}
      {required && <span className="text-danger align-middle">{' '}*</span>}
      {React.Children.count(children) > 0 ? (
        children
      ) : (
        <input
          className={classNames('form-control', { 'is-invalid': touched[name] && errors[name] })}
          type={type}
          id={id}
          name={name}
          value={values[name] || ''}
          required={required}
          onChange={handleChange}
          onBlur={handleBlur}
          {...props}
        />
      )}
      {showFeedback && touched[name] && errors[name] &&
        <div className="invalid-feedback">{errors[name]}</div>
      }
    </div>
  );
}
