import Select from 'react-select';
import { PurposeCode } from '../utils/purpose_code';

export default function PurposeSelect({ value, ...props }) {
  return (
    <Select
      options={Object.entries(PurposeCode).map(([value, label]) => ({ value, label }))}
      isClearable
      placeholder="Válassz..."
      value={value ? { value, label: PurposeCode[value] } : value}
      {...props}
    />
  );
}
