import classNames from 'classnames';

export default function PageContent({ id, title, className, children, isDark = false }) {
  return (
    <section id={id} className={classNames({ 'bg-dark text-white': isDark }, className)}>
      <div className="container">
        {title !== undefined &&
          <h2 className="text-center p-2">{title}</h2>
        }
        {children}
      </div>
    </section>
        
  );
}
