import { saveSvgAsPng } from 'save-svg-as-png';

export default function DownloadPngButton({
  label = 'PNG', elementId = 'qr-kod', fileName = 'qr-kod.png', options = { scale: 5 }
}) {

  function savePng() {
    const parser = new DOMParser();
    const doc = parser.parseFromString(
      Buffer.from(document.getElementById(elementId).src.substring(26), 'base64').toString(), "image/svg+xml"
    );

    saveSvgAsPng(doc.documentElement, fileName, options);
  }

  return (
    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={savePng}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
      </svg>{' '}
      {label}
    </button>
  );
}
