
export default function ToggleSimpleButtons({ isSimple, onChange }) {
  return (
    <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
      <input type="radio" className="btn-check" name="simple" id="simple_yes" autoComplete="off" checked={isSimple} onChange={() => onChange(true)} />
      <label className="btn btn-outline-primary" htmlFor="simple_yes">Egyszerűsített</label>

      <input type="radio" className="btn-check" name="simple" id="simple_no" autoComplete="off" checked={!isSimple} onChange={() => onChange(false)} />
      <label className="btn btn-outline-primary" htmlFor="simple_no">Bővített</label>
    </div>
  );
}
