import classNames from 'classnames';

export default function AmountField({ formik, name = 'amount', currency = 'HUF', ...props }) {
  const id = props.id || name;
  const { values, touched, errors, handleBlur, handleChange } = formik;

  return (
    <div className="input-group">
      <input
        className={classNames('form-control', { 'is-invalid': touched[name] && errors[name] })}
        type="number" 
        id={id}
        name={name}
        min="0"
        max="999999999999"
        maxLength="12"
        step="1"
        value={values[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
      <span className="input-group-text">{currency}</span>
      {touched[name] && errors[name] &&
        <div className="invalid-feedback">{errors[name]}</div>
      }
    </div>
  );
}
