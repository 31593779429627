
export function formatDateTime(str) {
  return `${str.replaceAll(/-|:|T|\//ig, '')}00+1`
}

export function getDateString(date) {
  return date.toISOString().substring(0, 16);
}

const LF = "\n";

export function generateQRValue({
  serviceTag = 'HCT', version = '001', characterSet = 1, currency = 'HUF',
  bic, name, iban, amount, expire, purpose, message, 
  shopID, merchDevID, invoiceID, customerID, credTranID, loyaltyID, NAVCheckID
}) {
  return [
    serviceTag, version, characterSet,
    bic.padEnd(11, 'X'), name, iban, amount ? `${currency}${amount}` : amount, formatDateTime(expire), purpose, message,
    shopID, merchDevID, invoiceID, customerID, credTranID, loyaltyID, NAVCheckID
  ].map(value => `${value}`.concat(LF)).join('');
}
