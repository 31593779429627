
export default function NavigationBar() {
  return (
    <header>
      <nav id="top-navigation" className="navbar fixed-top navbar-expand-md navbar-light bg-white border-bottom shadow-sm">
        <div className="container fw-bold">
          <a className="navbar-brand fs-3" href="#">QR transfer</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav text-uppercase">
              <li className="nav-item">
                <a className="nav-link active" href="#intro">Bevezető</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#generate">Generálás</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#usage">Használat</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
